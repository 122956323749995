.top_text {
    background: #ffffff;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.container {
    width: 88% !important;
}

.buton {
    background-color: #121212;
    border: none;
    margin: auto;
}

.buton:hover {
    border: none;
    background-color: #121212;
}

.buton:active {
    outline: none;
    background-color: #121212;
}

.buton a {
    font-weight: bold;
    color: white;
    text-decoration: none;
}

.ssss .col-sm-4 {
    border-right: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
}

.new_service {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 60px;
}

.new_service a {
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    background: #121212;
    padding: 8px 0px;
    width: 100%;
    display: block;
    border-radius: 4px;
    margin: auto;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    font-family: open sans, sans-serif;
    margin-top: 0px;
}

.new_service h4 {
    font-weight: bold;
}

.new_service img {
    width: 80px;
    margin: auto;
    transition: .5s;
}

.new_service:hover img {
    width: 80px;
    margin: auto;
    transform: scale(1.3);
    transition: .5s;
}

.offerbg {
    background: #000 url(./images/crc.jpg) center top no-repeat;
    background-size: cover;
    padding: 50px 0 40px;
    color: #ffffff;
    z-index: -1;
    transition: 2s;
    height: 532px;
}

.offerbg h2 span {
    text-align: center;
    font-weight: bold;
}

.offerbg h2 {
    text-align: center;
    font-weight: bold;
}

.offerbg p {
    color: #f7f7f7;
    font-size: 14px;
    line-height: 20px;
}

.greyli {
    background-color: #666;
    padding: 0;
    margin: 25px 0 25px 0;
    width: 100%;
    height: 1px;
}

.offerbox {
    color: #f7f7f7;
    font-size: 14px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.offerbox .img {
    float: left;
    width: 68px;
    margin: 0 15px 30px 0;
    text-align: center;
    transition: .3s;
}

.offerbox:hover .img {
    transform: scale(1.2);
    transition: .3s
}

.offerbox:hover h2 {
    color: #117
}

.oferbox p {
    display: flex;
}

.offerbox h3 {
    font-size: 20px !important;
    color: #ffffff !important;
    margin: 0px;
    font-weight: 800;
    margin-bottom: 10px;
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
}

.container {
    width: 88% !important;
}

.anim {
    float: left;
    visibility: visible;
    animation-duration: 1200ms;
    animation-delay: 400ms;
    animation-name: zoomIn;
}

.web-theme {
    padding: 70px 0 70px;
}

.top_text h2 {
    font-family: 'open Sans', 'sans-serif';
    font-family: 35px;
}

.top_text h2 span {
    color: #121212;
    font-weight: 800;
}

.img-newab img {
    display: inline-block;
    vertical-align: top;
    position: relative;
    /* left: 10; */
    top: 0;
}

.textttttt {
    margin-top: -32px;
    padding-left: 55px;
}

.textttttt h4 {
    padding-bottom: 5px;
}

.textttttt h4 {
    text-align: justify;
    font-size: 14px;
    line-height: 21px;
}

.home-1 {
    background: #ffffff url(./images/home-1.jpg) no-repeat center;
    background-size: cover;
    background-attachment: scroll;
    height: 580px;
    position: relative;
    overflow: hidden !important;
}

.home-1 .left {
    height: 100%;
    color: #fff;
    background-color: #121212;
    width: 40%;
    padding: 60px 100px 60px 30px;
}

.home-1 .left h4 {
    font-size: 28px !important;
    line-height: normal !important;
    color: #fff !important;
    font-weight: 700;
    height: auto;
    position: relative !important;
}

.home-1 .left p {
    color: #fff;
}

.home-1 .right {
    position: absolute;
    z-index: 1;
    right: 5%;
    top: 10%;
    width: 60%;
    height: 80%;
    color: #333;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 60px;
}

.home-1 .right ul {
    display: table;
    content: " ";
    clear: both;
}

.home-1 .right ul li {
    float: left;
    width: 33.33%;
    text-align: center;
    list-style: none;
    margin: 40px 0;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
}

.home-1 .right ul li img {
    clear: both;
    display: block;
    margin: 0 auto 10px auto;
    height: 50px;
}

.home-1:before,
.home-1:after {
    display: table;
    content: " ";
    clear: both;
}

.marketing-section-two {
    position: relative;
    text-align: center;
    padding: 160px 0px 90px;
    background-color: #ffffff;
    background-position: right top;
    background-repeat: no-repeat;
}

.marketing-section-two .steps-blocks {
    position: relative;
}

.marketing-section-two .theme-btn {
    padding: 9px 33px;
}

.step-block {
    position: relative;
    margin-bottom: 30px;
}

.step-block:nth-child(2) {
    margin-top: -20px;
}

.step-block:nth-child(3) {
    margin-top: -40px;
}

.step-block:nth-child(4) {
    margin-top: -80px;
}

.step-block .inner-box {
    position: relative;
    text-align: center;
}

.step-block .inner-box .icon-box {
    position: relative;
    width: 128px;
    height: 128px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 15px;
}

.step-block .inner-box h3 {
    position: relative;
    color: #0f1341;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4em;
    margin-bottom: 4px;
}

.step-block .inner-box h3 a {
    position: relative;
    color: #0f1341;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.step-block .inner-box h3 a:hover {
    color: #ff4800;
}

.step-block .inner-box .step-number {
    position: relative;
    color: #808080;
    font-size: 16px;
    font-weight: 400;
}

.marketing-section-two h3 {
    position: relative;
    color: #0f1341;
    font-size: 24px;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 18px;
    text-transform: uppercase;
}

.marketing-section-two .theme-btn {
    padding: 9px 33px;
}

.btn-style-one {
    position: relative;
    line-height: 24px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    background: #121212;
    display: inline-block;
    padding: 9px 26px;
    border-radius: 50px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 2px solid #121212;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}

.btn-style-one:hover {
    color: #ffffff;
}

.ready-for-next {
    margin: 20px auto;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #30333c;
    color: #fff;
    font-size: 24px;
    border-radius: 5px;
    overflow: hidden;
    clear: both;
}

.ready-for-next .tel,
.ready-for-next .text {
    float: left;
    /* -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box; */
    text-align: center
}

.prices-links div,
.ready-for-next .free-quote {
    float: left;
    /* -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box */
}

.ready-for-next .free-quote a,
.ready-for-next .tel a {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none
}

.ready-for-next .free-quote a:hover,
.ready-for-next .tel a:hover {
    text-decoration: none
}

.ready-for-next .text {
    width: 57%;
    font-size: 34px;
    box-sizing: border-box;
    color: #fff;
    border-radius: 5px 0 0 5px;
    padding: 10px 0 0;
}

.ready-for-next .tel {
    width: 23%;
    box-sizing: border-box;
    border-left: 1px solid #50535d;
    padding: 23px 0 20px;
}

.ready-for-next .free-quote {
    width: 20%;
    box-sizing: border-box;
    text-align: center;
    background: #121212;
    border-radius: 0 5px 5px 0;
    padding: 25px 0 21px;
}

.ready-for-next .free-quote a,
.ready-for-next .tel a {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
}

a i {
    padding-right: 10px;
}

.form-group {
    margin-bottom: 15px;
}

.bootom_form {
    background: url(./images/aami3-91-512.png) no-repeat 50% 50%;
    background-size: auto auto;
    background-size: cover;
    padding: 40px 0 60px;
    overflow: hidden;
}

.bootom_form h2 {
    font-size: 40px;
    color: #fff;
    font-weight: bold;
    font-family: 'Poppins',
        sans-serif;
}

.bootom_form p {
    font-size: 16px;
    color: #fff;
    font-family: 'Poppins',
        sans-serif;
}

.bootom_form .form-control {
    border-radius: 0px;
}

.bootom_form input.form-control {
    min-height: 44px;
}

.bootom_form input[type="checkbox"] {
    float: left;
    margin-right: 10px;
}

.bootom_form label {
    color: #fff;
}

textarea.form-control {
    height: 220px;
}

.cate {
    float: left;
    width: 25%;
}

@media (min-width: 576px) {
    .col-sm-6 {
        padding: 14px;
        float: left;
        flex: 0 0 auto;
        /* width: 50%; */
    }
}

@media(max-width:768px) {
    .col-sm-3 {
        /* width: 25%; */
        float: left;
    }

    .ssss .col-sm-4 {
        border-right: 0px solid #dadada;
        border-bottom: 1px solid #dadada;
    }

    .home-1>.right {
        position: relative;
        z-index: auto;
        right: auto;
        top: auto;
        width: 100%;
        height: auto;
        padding: 30px 60px;
    }

    .home-1>.right>ul {
        width: 100%;
    }

    .home-1>.right>ul>li {
        width: 33.33%;
        margin: 10px 0;
        text-transform: uppercase;
    }

    .home-1>.right>ul>li>img {
        height: 30px;
    }

    .home-1 {
        background-attachment: scroll;
        height: auto;
        position: relative;
        overflow: hidden !important;
    }

    home-1:before,
    .home-1:after {
        display: table;
        content: " ";
        clear: both;
    }

    .home-1>.left {
        height: auto;
        color: #fff;
        background-color: #121212;
        width: 100%;
        padding: 30px;
    }

    .home-1>.left>h4 {
        font-size: 20px !important;
        line-height: normal !important;
        color: #fff !important;
        padding: 0 !important;
        margin: 0 !important;
        position: relative !important;
        text-transform: uppercase;
        height: auto;
        position: relative !important;
    }

    .home-1>.right {
        position: relative;
        z-index: auto;
        right: auto;
        top: auto;
        width: 100%;
        height: auto;
        padding: 5px;
    }

    .home-1>.right>ul {
        width: 100%;
        padding: 0px
    }

    .home-1>.right>ul>li {
        width: 50%;
        margin: 10px 0;
        font-size: 13px;
        text-transform: uppercase;
    }

    .home-1>.right>ul>li>img {
        height: inherit;
        width: 40px;
    }

    .img-newab img {
        width: 35px;
    }
}

@media only screen and (max-width:700px) {
    .offerbox .img {
        width: 40px;
    }

    .offeerbg h3 {
        font-size: 16px !important;
    }

    .offerbox {
        margin: 10px 0ps;
    }

    .offerbox p {
        display: flex;
    }

    .ready-for-next {
        margin-top: 20px !important;
    }

    .ready-for-next .text {
        line-height: 31px;
        width: 100%;
        font-size: 30px;
        padding: 15px 0 0;
    }

    .ready-for-next .tel {
        width: 100%;
        box-sizing: border-box;
        border-left: 1px solid #50535d;
        padding: 23px 0 20px;
    }

    .ready-for-next .free-quote {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        background: #50c222;
        border-radius: 0 5px 5px 0;
        padding: 15px 0 15px;
    }

}

@media(max-width:1024px) {
    .offerbg {
        height: 1250px;
    }
}

@media only screen and (max-width:992px) {
    .home-1 {
        background-attachment: scroll;
        height: auto;
        position: relative;
        overflow: hidden !important;
    }

    .home-1::before,
    .home-1::after {
        display: table;
        content: " ";
        clear: both;
    }

    .home-1 .left {
        height: auto;
        color: #fff;
        background-color: rgba(236, 51, 69, .95);
        width: 100%;
        padding: 30px;
    }

}