.aboutsss_sss {
    padding: 10px 0px 20px;
}
.aboutsss_sss p {
    color: #3b3939;
    font-size: 14px;
    text-align: justify;
}
h2, .h2 {
    font-size: 26px;
}
h1 span, h2 span {
    color: #ec595f;
}