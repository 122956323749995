.navbar .container-fluid .navbar-brand .logostyle {
  width: 228px;
  height: 50px;
  margin-left: 0px;
  /* background-color: #474747; */
}

.query {
  margin-top: -2px;
  color: white;
  text-decoration: none;
}

.query:hover {
  text-decoration: none;
  color: white;
}

.bbt {
  border: none;
  outline: none;
  background-color: #121212;
}

.bbt:hover {
  outline: none;
  background-color: #d10000
}

.bbt a {
  text-transform: none;
}

.site_nav {
  position: absolute;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  z-index: 999;
  top: 100px;
  box-shadow: 2px 5px 10px;
}

.nav-site {
  background: none;
  border: 0px solid #ffffff;
  margin-bottom: 0px;
}

.navbar-default .navbar-nav li a {
  font-size: 12px;
  color: #474747;
  padding: 5px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: normal;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none;

}

.nav-style .navbar-nav li a .icon em {
  vertical-align: top;
  padding-left: 1px;
  font-size: 10px;
  display: inline-block;
  font-style: normal;
  line-height: 15px;

}

.nav-style .navbar-nav li a .icon {
  text-align: center;
}

.nav-style .navbar-nav li a .icon i {
  display: inline-block;
  vertical-align: top;
  margin-left: 16px;
}

.nav-style .navbar-nav {
  margin-top: 10px;
}

.site_nav .navbar-default .navbar-nav li {
  float: none;
}

.nav-style .navbar-nav li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

@media(min-width:768px) {
  .navbar-nav li {
    float: left;
  }

  .navbar .container-fluid .navbar-brand .logostyle {
    width: 228px;
    height: 44px;
    margin-left: 0px;
  }
}

@media(max-width:768px) {
  .navbar .container-fluid .navbar-brand .logostyle {
    width: 138px;
    height: 44px;
    margin-left: 0px;
  }

  .site_nav {
    opacity: 0;
  }
}