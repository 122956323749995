.development_bg {
    background: url(./images/bg.jpg);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}

.ineer_serv_textt {
    margin-top: 10%;
    margin-left: 20px;
}

.ineer_serv_textt h2 {
    color: #fff;
    font-weight: 600;
    font-size: 35px;
    text-shadow: 0px 0px 10px #000;
}

.ineer_serv_textt p {
    color: #fff;
}

.technical_form {
    right: 0;
    /* background: ; */
    margin-right: 10%;
    margin-top: 20px;
    margin-bottom: 30px;
}

.technical_form h4 {
    font-size: 19px;
    font-weight: 600;
    color: #fff;
    margin: 0px;
    background: #121212;
    border-radius: 6px 6px 0px 0px;
    padding: 15px 0px;
    text-align: center;
}

.iner-formm {
    background: #f1f1f1;
    padding: 15px 20px;
}

.iner-formm .form-group {
    margin-bottom: 10px;
}

.iner-formm input.form-control {
    min-height: 37px;
    font-size: 14px;
}

.iner-formm .btn-info {
    width: 100%;
    background: #121212;
    border-radius: 20px;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    padding: 11px;
    text-align: center;
    border: none;
}

.bedcrumb_service {
    background: #f5f5f5;
}

.bedcrumb_service .breadcrumb {
    margin: 0px;
}

.breadcrumb>li {
    display: inline-block;
}

.bedcrumb_service li a {
    color: #000;
}

.bg_none {
    background: none;
}

.top_text {
    background: #f9f9f9;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.container {
    width: 88% !important;
}

.top_text h2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 35px;
}

.top_text h2 span {
    color: #121212;
    font-weight: 800;
}

body,
p,
.single-post h6,
.page-bannar h1,
footer ul.list-group li,
.comming-soon,
.comming-soon h1 {
    font-family: 'Open Sans', sans-serif;
}

body,
p {
    font-size: 16px;
    color: #002e3b;
}

.mobile-text h2 {
    color: #000;
    font-weight: 600;
    font-size: 24px;
}

.mobile-text h2 span {
    color: #121212;
}

.mobile-text p {
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
}

.web_sub_Serv {
    margin-top: 30px;
}

.web_sub_Serv img {
    width: 70px;
}

.web_sub_Serv h4 {
    font-weight: 700;
    color: #000;
    font-size: 18px;
}

.web_sub_Serv a {
    font-size: 15px;
    color: #121212;
    font-weight: 600;
    text-decoration: none;
}

.services_inn_bg {
    background: #f5f5f5;
    padding: 10px 15px 10px 15px;
}

.website-service-head {
    font-weight: 600;
    font-size: 20px;
    color: #2d2d2d;
    padding: 17px 0px 10px 0px;
    margin: 0px;
}

.mobile_list {
    padding: 0px;
    margin: 0px;
}

.mobile_list li {
    list-style: none;
    font-size: 15px;
    line-height: normal;
    display: flex;
    margin-bottom: 6px;
}

.mobile_list i {
    margin-right: 5px;
    color: #121212;
    margin-top: 4px;
}

.right-web-services {
    margin-left: 10px;
}

.right-web-services h4 {
    font-weight: 600;
    font-size: 18px;
}

.right-web-services ul {
    padding: 0px;
    margin: 0px;
}

.right-web-services ul li {
    list-style: none;
    border-bottom: 1px dashed #ccc;
    padding: 7px 0px;
}

.right-web-services ul li i {
    margin-right: 8px;
    font-size: 15px;
    color: #067b29;
}

.right-web-services ul li a {
    color: #000;
    font-weight: 500;
    font-size: 14px;
}

.box-header:after {
    display: block;
    content: "";
    background: #121212;
    width: 40px;
    height: 4px;
    margin-top: 9px;
}


/* .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
} */
@media (max-width: 700px) {
    .development_bg {
        background-size: 100% 100%
    }

    .col-sm-4 {
        padding-left: 35px;
    }

}