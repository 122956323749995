.imgs {
    background: url(images/bg22.jpg);
    background-position: center;
    background-repeat: no-repeat;
}

.seo-img {
    margin: 0 auto;
}

.box {
    width: 33.33%
}

.details {
    width: 249%;
}

@media (max-width: 700px) {
    .box {
        width: 173px;
        float: left;
        margin-left: -25px;
        margin-right: 25px;
    }
}