.imgg {
    background: url(images/web-design-prices.jpg)
}

.head.portfolio {
    padding: 30px 0px;
}

.container {
    width: 88% !important;
}

.head h1 {
    font-size: 45px;
    color: #fff;
}

.bedcrumb_service {
    background: #f5f5f5;
}

.bedcrumb_service .breadcrumb {
    margin: 0px;
}

.breadcrumb>li {
    display: inline-block;
}

.bedcrumb_service li a {
    color: #000;
}

.bg_none {
    background: none;
}

.top_text h2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 35px;
}

body,
p,
.single-post h6,
.page-bannar h1,
footer ul.list-group li,
.comming-soon,
.comming-soon h1 {
    font-family: 'Open Sans', sans-serif;
}

.mobile-text h2 {
    color: #000;
    font-weight: 600;
    font-size: 24px;
}

.mobile-text p {
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
}

.mobile-text h2 span {
    color: #121212;
}

.list_who {
    padding: 0px;
    margin: 0px;
}

.list_who li {
    list-style: none;
    color: #4b4b4b;
    margin-bottom: 5px;
}

.list_who li i {
    color: #121212;
    margin-right: 5px;
}

.ineer_laptopslider {
    position: relative;
    text-align: center;
    margin-top: 50px;
}

.call_middle {
    color: #000;
    font-weight: 800;
    font-size: 30px;
}

.bg_none {
    background: none;
}

.top_text {
    background: #fff;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.top_text h2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 35px;
}

.top_text h2 span {
    color: #121212;
    font-weight: 800;
}

.hovereffect {
    width: 100%;
    height: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
}

.hovereffect img {
    display: block;
    position: relative;
}

.hovereffect .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    background-color: rgba(75,
            75,
            75,
            0.7);
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.hovereffect:hover .overlay {
    background-color: rgba(48,
            152,
            157,
            0.4);
}

/* .hovereffect .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    background-color: rgba(75, 75, 75, 0.7);
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
} */
.hovereffect h2 {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 26px;
    font-weight: 600;
    padding: 10px;
    background: rgba(0, 0, 0, 0.6);
    -webkit-transform: translateY(45px);
    -ms-transform: translateY(45px);
    transform: translateY(45px);
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    margin-top: 81px;
}

.hovereffect:hover h2 {
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
}

.hovereffect p.info {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    color: #fff;
    background-color: transparent;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    font-weight: normal;
    margin: 7px;
}

.hovereffect:hover p.info {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

/* .hovereffect p.info:hover {}
.se0_img img {} */
.ready-for-next {
    margin: 20px auto;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #212121;
    color: #fff;
    font-size: 24px;
    border-radius: 5px;
    overflow: hidden;
    clear: both
}

.portfolio_button {
    display: inline-block;
    padding: 10px 20px;
    background: #121212;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
}

.ineer_laptopslider {
    position: relative;
    text-align: center;
    margin-top: 50px;
}

.img-responsive,
.thumbnail>img,
.thumbnail a>img,
.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
    display: block;
    max-width: 100%;
    height: auto;
}

.portfolio_button:hover {
    color: white;

}