.web_sub_Serv {
    margin-top: 30px;
}

.web_sub_Serv img {
    width: 70px;
}

.web_sub_Serv h4 {
    font-weight: 700;
    color: #000;
    font-size: 18px;
}

.mobile-text p {
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
}

.web_sub_Serv a {
    font-size: 15px;
    color: #121212;
    font-weight: 600;
}

.right-web-services {
    margin-left: 10px;
}

.right-web-services h4 {
    font-weight: 600;
    font-size: 18px;
}

.right-web-services ul {
    padding: 0px;
    margin: 0px;
}

.right-web-services ul li {
    list-style: none;
    border-bottom: 1px dashed #ccc;
    padding: 7px 0px;
}

.right-web-services ul li i {
    margin-right: 8px;
    font-size: 15px;
    color: #121212;
}

.right-web-services ul li a {
    color: #000;
    font-weight: 500;
    font-size: 14px;
}