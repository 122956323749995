/* --------------------------------------
=========================================
RESPONSIVE FIXES
=========================================
-----------------------------------------*/
.no-padding{
    padding: 0px !important;
}
.no-padding-top{
    padding-top: 0px !important;
}
.no-padding-bottom{
    padding-bottom: 0px !important;
}
.no-padding-left{
    padding-left: 0px;
}
.no-padding-right{
    padding-right: 0px;
}
.no-margin{
    margin-bottom: 0px !important;
}
.no-mt{
    margin-top: 0px !important;
}
.mt-100{
    margin-top: 100px ;
}
.mb-60{
    margin-bottom: 60px;
}
.mb-50{
    margin-bottom: 50px;
}
.pt-100{padding-top: 100px !important;}

 @media (max-width: 1366px) {
  section.featured-course::before{
  width: 35%
}
section.featured-course::after{
  width: 65%
}
.related-post .single-post .text {

    margin-top: 0px !important;
}
section.fun-facts {
  margin-left: 0;
  width: 100%;
}
section.fun-facts::after,section.fun-facts::before{display: none}






 }


@media (min-width: 992px) and (max-width: 1024px) {

.navbar-default .navbar-nav > li > a {
  font-size: 13px;
  padding: 15px;
}
.have-sidebar .icon-box .icon {
 
  font-size: 30px;
  height: 70px;
  margin-right: 15px;
  padding-top: 15px;
  text-align: center;
  width: 70px;
}
.icon-box .icon {
 
  font-size: 30px;
  height: 70px;
  margin-right: 15px;
  padding-top: 15px;
  text-align: center;
  width: 70px;
}
.ico-details {
  float: right;
  width: 60%;
}
.upcoming-course .course-price {
  font-size: 18px;
  margin: -10px 0;
}
.upcoming-course .post-in {

  margin-top: -5px;
}
.upcoming-course h5 a {

  font-size: 17px;
  line-height: 22px;
  margin-top: 14px;
}
.ceo p {
  margin-bottom: 18px !important;
}
.gmap {
  width: 100%;
}
.related-post .single-post .text {
    margin-bottom: 20px !important;
    margin-top: 0;
}
.events-list p.text {
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.amount-per-month {
    width: 142%;
}
.amount-per-month::after {
  border-left: 101px solid transparent;
  border-right: 101px solid transparent;
}
.events-list .post-date {
    margin-bottom: 70px;
}
.related-post .single-post {
    display: block;
    margin-bottom: 30px;
    overflow: hidden;
}
.events-list .col-md-12 {
    margin-bottom: 0;
}
.Breadcrumbs {
 
  margin-top: -101px;

}
.course-lesson.course-item .index {
    margin-right: 20px;
}
.course-lesson.course-item span {
    float: left;
    }
.course-lesson.course-item {
    overflow: hidden;
}
span.lesson-title {
    display: block;
    font-size: 16px !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 15px;
    padding-top: 0 !important;
    width: 70%;
}
ul.list-group {
    font-size: 14px;
}
.course-list .details {
    width: 457px;
}
.course-list h3 a {
  font-size: 18px;
}
p.course-price {
    font-size: 24px;
}
.course-list .text {
    margin-bottom: 5px;
}
.course-list .col-md-12 img {
    height: 200px;
    width: 240px;
}
.nav-style .navbar-nav .submenu ul > li > a {

  font-size: 13px !important;
  padding-bottom: 10px !important;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}
.navbar-default .navbar-nav > .btn-style1 > a {
  padding: 10px !important;
}
.page-content {
  padding-top: 50px;
  padding-bottom: 50px;
}
.sidebar .post-img {
  width: 60px;
}
footer .post-img {
  float: left;
  margin-right: 12px;
}
ul.footer-post .img-radius {
  height: 60px;
  width: 60px;
}
.featured-course-tp h1,.c-details h1 {
  font-size: 28px;
  line-height: 28px;
  margin: 0 0 15px;
}
.featured-course-tp {
  float: right;
  padding: 0 20px;
  width: 180px;
}
.featured-course .c-details {
  padding-left: 55px;
 
}
.c-details .post-in {
  margin-bottom: 10px;
}
.btn {
  
  font-size: 15px;
  padding: 10px 25px;
}


span.title {
  font-size: 17px;
line-height: 22px;
width: 57% !important;
}

.hv2 .signle-fid {
 
  padding: 15px 10px;

}
.events h2 a {
 
  font-size: 17px;
  line-height: 24px;
}
.latest-news h5 {
  margin-top: 15px;
}
body, p {
  font-size: 14px;
}
.single-professor li a {
  color: #babbbb;
  padding: 0;
}




 }
    

 /*Tablet */ /*Tablet */ /*Tablet */ /*Tablet */ /*Tablet */
 /*Tablet */ /*Tablet */ /*Tablet */ /*Tablet */ /*Tablet */
 /*Tablet */ /*Tablet */ /*Tablet */ /*Tablet */ /*Tablet */
 /*Tablet */ /*Tablet */ /*Tablet */ /*Tablet */ /*Tablet */
 /*Tablet */ /*Tablet */ /*Tablet */ /*Tablet */ /*Tablet */
 
@media (min-width: 768px) and (max-width: 991px) { 
    
    html {
        font-size: 90%;
    }
    
  .icon-box .icon {
  float: none;
  margin-bottom: 20px;
} 
.pull-left,.pull-right{float: none !important}
.top-social-bar {
  display: none;
}
.ico-details {
  float: right;
  width: 100%;
}
.no-padding {
  padding: 0 15px !important;
}
.single-professor li a {
  padding: 0px;
}
.social-vertical li a {

  padding: 20px 10px;
 
}
.latest-course img {
  width: 100%;
}
section.course-event::after,
section.fun-facts::before,
section.fun-facts::after{display: none}
section.course-event::before{width: 100%}
.awards-area li {
  text-align: center;
}
.cnp .owl-controls {
  margin-top: 20px;
}
.ceo {margin-left: 0;
padding-bottom: 14px !important;
padding-left: 100px !important;
padding-top: 30px !important;
}
.social-vertical{
  left: 0px;
}
.ceo p {
  margin-bottom:18px !important;
}
.signle-fid {
  margin-bottom: 30px;
}
.amount-per-month {
    width: 122%;
}

.no-padding-right{
  padding-right: 15px !important;
}
.no-padding-left {
    padding-left: 15px !important;
}

.sm-padding-no{padding: 0px !important}
.no-pb-sm{
  padding-bottom: 0px !important;
}
.gmap {
  height: 375px;
  width: 100%;
  margin-top: 50px;
}
.no-padding-bottom{padding-bottom: 0px !important;}
.amount-per-month::after {
  border-left: 163px solid transparent;
  border-right: 163px solid transparent;
}
section.icon-text-box {
  padding-bottom: 50px;
}
section.contact-form {
  padding: 50px 0;
}
.related-post .single-post .text {
     margin-bottom: 15px !important;
}
.contact-form.comment-form-area input {
    margin-bottom: 0 !important;
}
.comment-form-area div {
    padding-bottom: 0 !important;
}
.Breadcrumbs{margin-top: -101px;}
.page-content {
    padding:50px 0px;
}
.widget:last-child{
  margin-bottom: 0px;
}
.events-list p.text {
    margin-bottom: 24px;
}
.tab-pane .col-sm-4 {
  margin-bottom: 30px;
}
.latest-news {
  display: block;
}

.latest-news .col-md-6.no-padding-left {
  overflow: hidden;
}
.subcribe-area h3 {

  margin: 15px 0 20px;
  text-align: center;
}

.awards-area h1 {
  margin-top: 0;
}
.sidebar {
    margin-top: 0px;
}
.cnp .owl-nav {
  position: static;

}
.events-list .post-date {
    margin-bottom: 80px;

}
.signle-fid p {
    font-size: 16px;
}
.signle-fid h4 {
  
    font-size: 35px;
    margin-bottom: 15px;
}
.events-list .col-md-12 {
    margin-bottom: 0;
}
.course-list .col-md-12 {
    margin-bottom: 0;
}
.course-list .details {
    border-top-right-radius: 0;
    height:  240px;
    margin-bottom: 0;
    position: relative;
    top: -5px;
    width: auto;
}
.course-list .pagi {
    width: 100%;
}

.course-list .details {
    border-bottom-left-radius: 5px;
   }
.navbar-logo {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.contact-form input, .contact-form textarea {  
    margin-bottom: 15px;

}
a.img-link {
  width: 100%;
}

.latest-course .details {
	min-height: 179px;
}
.content p {
    line-height: 26px;
}
.course-lesson.course-item .index {
    float: left;
}
span.meta {

    display: inline-block;
    /* float: left; */
}
span.lesson-title {
   display: block;
    float: left;
    overflow-wrap: break-word;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 15px;
    padding-top: 0;
    width: 50%;
    font-size: 15px !important;
}  
.course-lesson.course-item {
    overflow: hidden;

}
.mt-100 {
    margin-bottom: 30px;
    margin-top: 50px !important;
}
.cs-center {

  margin-top: 10px;
}
.single-student p:first-child::before{display: none;}
h5 .pull-right {
    float: right !important;
}
.course-list .col-md-12 img {

   border-top-right-radius: 5px !important;
width: 100%;
}
.latest-course .search-text {
  margin-bottom: 25px !important;
}
.course-list .details {
height: auto;
margin-bottom: 30px;
width: 100%;
border-bottom-left-radius: 5px;
  border-top-right-radius: 0 !important;
  margin-top: 0px !important;
}
.signle-fid {
  margin-bottom: 0;
}
.course-list h3 a{
color: #002e3b;
display: block;
overflow: hidden;
text-overflow: none;
white-space: normal;

}
.sidebar ul li a {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
}
ul.footer-post .img-radius {
  height: 70px;
  width: 70px;
}
.widget-post {

  width: 60%;
}
.navbar-header {
  text-align: center;
  width: 100%;
}
.top-bar-left.pull-left {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
.hv2 .signle-fid {
  margin-bottom: 30px;
}
.course-details-sidebar li span {
  text-align: left;
  width: 70%;
}
.footer-bottom ul {
  margin-bottom: 10px;
  text-align: center;
}
.top-bar {
  display:none;
}
.counter li {
  margin-right: 10px;
}
.counter span {
  font-size: 40px;
}
.counter p {
  color: #fff;
  display: block;
  float: none;
  font-size: 18px;
  position: static;
}
ul.footer-post {
  margin-bottom: 25px;
  padding: 0;
}
}

/*Small Devices*//*Small Devices*//*Small Devices*//*Small Devices*/
/*Small Devices*//*Small Devices*//*Small Devices*//*Small Devices*/
/*Small Devices*//*Small Devices*//*Small Devices*//*Small Devices*/
/*Small Devices*//*Small Devices*//*Small Devices*//*Small Devices*/
/*Small Devices*//*Small Devices*//*Small Devices*//*Small Devices*/
/*Small Devices*//*Small Devices*//*Small Devices*//*Small Devices*/



@media(max-width:767px) {
  .content p {
    line-height: 26px;
}  
.hovereffect h2 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 17px;
  font-weight: 600;
  /* padding: 3px; */
  background: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(45px);
  -ms-transform: translateY(45px);
  transform: translateY(45px);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin-top: 76px;
}
.portfolio_button {
  display: inline-block;
  padding: 8px 12px;
  background: #59c178;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
}
.top-bar {
  display:none;
}
.counter li {
  margin-right: 10px;
}
.counter span {
  font-size: 40px;
}
.counter p {
  color: #fff;
  display: block;
  float: none;
  font-size: 18px;
  position: static;
}
.x-mb-50 {
    margin-bottom: 50px;
}
button.input-button {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 3px;
  height: 45px;
 
}
.comming-soon h1 span {
  font-size: 80px;
}
.gmap {
  width: 100%;
}
.footer-bottom ul {
  margin-bottom: 10px;
  text-align: center;
}
.pull-left,.pull-right{float: none !important}
section.fac{
	padding:0px !important;
	padding-bottom: 50px !important;
}
.navbar-header {
  text-align: center;
  width: 100%;
}
.no-padding-right {
  padding-right: 15px;
}
.no-padding{
	padding-left: 15px !important;
	padding-right: 15px !important;
}
.no-padding-left {
  padding-left: 15px !important;
}
.page-content .discover {
	padding: 50px 0 !important;
padding-bottom: 0px !important;
}
.have-sidebar .icon-box .icon {
 
    float: none;
}
.icon-box {
  margin-bottom: 20px;
  overflow: hidden;
}
.ico-details {
  float: right;
  text-align: center;
  width: 100%;
}
.icon-box .icon {
  float: none;
  margin-bottom: 15px;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 0 !important;
}
.gmap {
  margin-top: 30px;
}
.fac .container{
	border-radius: 0px
}
section.blog-news ,
section.cnp,.hv2 section.fun-facts,
section.latest-course,section.discover,
section.events,section.events,section.hv2-latest-course{
  padding: 50px 0;
}
.hv2-course-list .owl-controls {
  margin-top: 20px;
}
.single-professor li a {
  padding: 0;
}
.cnp .owl-controls {
  margin-top: 20px;
}
.event-spekers .owl-controls {
  margin-top: 20px;
}
.event-spekers .cnp {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.post-in {
  font-size: 14px;
}
.panel-title {
  font-size: 14px;
}
.panel-group .panel-title span i {
display: block;
left: -2px;
margin-right: 0;
margin-top: 0;
position: relative;
top: -1px;

}
.panel-body {
  font-size: 15px;
  line-height: 22px;
  padding: 25px;
}
.tab-content > .tab-pane {
 
  margin-top: -5px;
}
.accodion-down i {
  display: block !important;
  margin-top: -2px !important;
  padding: 0;
}
.panel-group .panel-title span {

  height: 20px;
  margin-top: 5px;
  text-align: center;
  transform: rotate(0deg);
  width: 20px;
}
.panel-default > .panel-heading {
  background-color: #f5f5f5;
  font-size: 16px;
  padding: 10px 15px;
}
.no-pb-sm{padding-bottom: 0px !important;}
.no-padding-bottom{padding-bottom: 0px !important;}
.navbar-collapse {
  
    width: 100%;
}
.course-list .text {
  display: none;
}
.latest-course .details {
  min-height: auto;
  padding: 15px 20px 0;
}
.contact-form input {
  margin-bottom: 15px !important;
}
.blog-news .single-post {
    margin-bottom: 30px !important;
}
.contact-form input, .contact-form textarea {
    margin-bottom: 15px;
}
section.contact-form {
    padding: 50px 0;
}
.events-list .col-md-12 {
  margin-bottom: 0;
}
.comment-area > .style2 {
    margin-left: 40px;
}
.single-content-area h1:first-child {
  width: 100%;
}
.widget:last-child {
    margin-bottom: 0;
}
.related-post .single-post .text {
    margin-bottom: 20px !important;

}
.events-list .post-date {
    bottom: 0;
    left: -100%;
    margin-bottom: 10px;
    position: absolute;
}
.events-list .post-date {
    margin-bottom: 10px;

}
.social-profile li {
    padding-top: 0;
}
h3.professor-name {
    margin-top: 30px;
}
.page-content p {
    line-height: 26px;
    margin-bottom: 10px;
}
.mt-100 {
    margin-bottom: 30px;
    margin-top: 50px !important;
}
img{width: 100%}
.f
.hv2 header, .hv2 .nav-style {
  background: black none repeat scroll 0 0;

}
.events-list .single-post img{border-bottom-right-radius: 0px}
.course-lesson.course-item {
    overflow: hidden;

}
.course-lesson.course-item .index {
    float: left;
}
span.meta {

    display: inline-block;
    /* float: left; */
}
span.lesson-title {
  display: block;
    float: left;

    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 15px;
    padding-top: 0;
    width: 66%;
}
.content-area{padding: 15px}
p.course-price {
    font-size: 20px;
}
.hv2 .homepage-slider {

  top: 0;
}
.awards-area li {
  
    width: 32% !important;
}
.hv2 .slider-text {
  padding-top: 50px;
}
.signle-fid {
    padding: 25px 0px;
}
	.navbar-logo {
display: block;
    float: none;
    margin: 10px auto;
    width: 230px;
    margin-bottom: 0px;
}
.navbar-toggle {
    border-color: #ddd;
    margin: 0;
    top: -55px;
}

.nav-style {
    height: auto;
}
.course-event h1 {
    margin-top: 0px;
}
body {
	font-size: 15px;
	line-height: 21px;

}
h1{
	font-size: 30px;
}
.ineer_serv_textt h2{
  font-size: 26px;
}
.mobile-text-box {
  background: #f7f7f7;
  padding: 2px 7px;
}
h2{
	font-size: 22px
}
h3{
	font-size: 20px
}
h4 {
	font-size: 18px;
	line-height: 28px;
}
.call_middle {
  color: #000;
  font-weight: 800;
  font-size: 25px;
}
.text-head {
  font-size: 31px;
  color: #000;
  margin-top: 0px;
}

h5 {
	font-size: 16px;
	line-height: 24px;
}

h6 {
	font-size: 16px;
	line-height: 22px;
}
.latest-course h1 {
    padding: 0px 15px;

    margin-bottom: 30px;
}
p.course-price {
    font-size: 22px;
}
.latest-course img {
    width: 100%;
}
.latest-course .col-md-3 {
    margin-bottom: 30px;
}
.nav-pills>li {
    margin-bottom: 5px;
}
.single-feature {
    padding: 50px 25px;
}
span.e-date {
  display: block;
  font-size: 15px;
  text-align: right;
  width: 50%;
  float: left;
  padding-right: 5px;
}
span.e-month {
  display: block;
  float: left;
  font-size: 15px;
  margin-top: 0;
  padding-left: 5px;
  text-align: left;
  width: 50%;
}
.post-date {
  border-radius: 0;
  width: 100%;
   margin: -5px 0 15px;
}
input.form-control,select.form-control {

  font-size: 14px;
  min-height: 45px;
}
ul.course-category {
  display: block;
  margin-bottom: 30px;
 
  overflow: hidden;
}

.mb-50{margin-bottom: 50px;}
.hv2 section.cnp {
  padding: 0 !important;
}

.hv2-course-list .owl-nav {
    position: static;
}
.img-box {
 
  height: auto;
}
.c-details h1 {
  color: #fff;
  margin-top: 0;
}

.hv2 .signle-fid {
  margin-bottom: 30px;
}
.hv2 .signle-fid i {

  padding-top: 0;
}
.hv2 .signle-fid p {
  margin-top: 0;
}
.signle-fid h4 {
    font-size: 28px;
}
.latest-news h5 {
  margin-top: 15px;
}
.latest-news p{
    margin-bottom: 15px !important;
}
.hv2 .events{
    padding-top: 0 !important;
}
.latest-news{
  display: block;
}
.latest-news .col-md-6.no-padding-left {
  overflow: hidden;
}
.latest-news p:last-child {
  margin-bottom: 30px !important;
}
.amount-per-month {
    width: 128%;
}
.amount-per-month::after {
  border-left: 135px solid transparent;
  border-right: 135px solid transparent;
}

.featured-course-tp {
  width: 100%;
  text-align: center;
}
.single-course-list {
  text-align: center;
}
.featured-course .img-box {
  float: none;
  margin: 0 auto;
}
section.featured-course::before {
  width: 100%;
}
section.featured-course::after {
  display: none;
}

.awards-area h1 {
    margin-top: 0px;
}
.btn {
    padding: 10px 25px;  
    display: inline-block;
    font-size: 14px;
}
.cnp h1 {
    margin-top: 0px;
}
.cnp .owl-nav {
    position: static;
   
}
.top-bar{
	height: auto;
}
.top-bar ul {
    margin-top: 0;
    margin-bottom: 0;
    padding: 10px;
}
.top-bar li {
    margin-bottom: 5px;
}
.top-bar ul i {
   
    font-size: 13px;
    width: 15px;
}
.single-post h2 {

    margin-top: 5px;
}
.single-post {
    margin-bottom: 30px !important;
}
.single-post p {
    margin-bottom: 25px;
}
.single-post h6 {

    margin-top: 15px;
    margin-bottom: 0px;
}
.blog-news h1 {
    margin-bottom: 25px;
}
.signle-fid i {
    font-size: 34px;
}
.single-post h2 a {
 font-size: 17px;
    line-height: 24px;
  
}
footer .widget h3 {
    margin-top: 10px;
}
.subcribe-area h3 {
 
    margin: 15px 0px;
    text-align: center;
}
.signle-fid p {
    font-size: 16px;
    margin-bottom: 0px;
}
.signle-fid h4 {
    font-size: 34px;
  
}
.slider-bottom-features{
	background: #fff !important;

}
.slider-bottom-features p {

    margin-bottom: 25px;
}
.footer-bottom {
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.slider-text {

    padding-top: 50px;
    padding-bottom: 50px;
}
section.slider-bottom-features .col-md-4 {
    margin-top: 40px;
}
section.slider-bottom-features .col-md-4:first-child {
    margin-top: 0px;
}
.slider-bottom-features .container{
	margin-top: 0px;
}
.list-group-item {
 
    padding: 15px 0px;
    font-size: 15px;
    
}
.homepage-slider p {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 30px;

}
header .container {
    padding: 0px;
}
.homepage-slider h3 {
    padding-bottom: 15px;
        margin-top: 0px;
}
.homepage-slider h1 {
 
    font-size: 30px;
}

section.course-event:after,section.fun-facts:before,section.fun-facts:after{
	display: none;
}	
section.course-event:before{
	width: 100%;
}
.discover .col-md-4 {
  margin-bottom: 0px;
  overflow: hidden;
}
body, p {

    font-size: 15px;
}
.icon-box .icon {
font-size: 26px;
height: 80px;
margin-right: 20px;
padding-top: 30px;
width: 80px;
}
a.img-link {
  width: 100%;
}

.Breadcrumbs {
  float: left;
  margin-top: -70px;
  margin-left: -10px;
}
.pagi {
  height: auto;
  overflow: hidden;
}
.latest-course .details {
  min-height: 150px;
}
.sidebar {
    margin-top: 50px;
}

.page-content {
  padding-bottom: 50px;
  padding-top: 70px;
}
.page-bannar {
  height: auto;

}
.page-bannar h1 {
 font-size: 30px;
  padding: 50px 0 5px;
  position: relative;
}
.course-list .col-md-12 {
  margin-bottom: 0;
}
.course-list .col-md-12 img {
   border-top-right-radius: 5px !important;
width: 100%;
}
.latest-course .search-text {
  margin-bottom: 25px !important;
}
.course-list .details {
height: auto;
margin-bottom: 30px;
width: 100%;
border-bottom-left-radius: 5px;
  border-top-right-radius: 0 !important;
  margin-top: -6px !important;
}

.course-list h3 a{
color: #002e3b;
display: block;
overflow: hidden;
text-overflow: none;
white-space: normal;

}
.course-list .col-md-12 img {
    height: auto;
}
.testimonial{
  padding: 50px 0px;
}
.single-student p:first-child::before{display: none;}
.course-list .pagi {
    overflow: hidden;
    width: 100%;
}
.single-professor .social {
    display: none;
}
.ceo {
    margin-left: 0;
    padding: 20px !important;
    text-align: left !important;
}

.single-professor ul {
    margin-bottom: 10px;
    margin-top: 0;
}
h5 span {
    color: #ec595f;
}
.mb-60 {
  margin-bottom: 30px;
}
.single-post {
    margin-bottom: 0;
}
.professor-area {
    padding: 50px 0 !important;
}
.single-professor > p {
    margin-bottom: 15px;
}
.xs-padding-no{padding: 0px !important}
.events-list .post-details {
border: 1px solid #ececec;
border-top-left-radius: 0;
border-top-right-radius: 0;
margin-top: -5px;
padding-bottom: 15px;
border-bottom-left-radius: 5px;
}
.events-list .post-date {
    border-top-right-radius: 0;
}
.events .single-post h2 {
    padding: 10px;
}
.single-post .post-element {
    margin-bottom: 10px;
    padding: 0px 10px;
}
.events-list p.text {
    margin-bottom: 10px;
    overflow: hidden;
    
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
   
}
.events .single-post h2 {
    padding-left: 0px;
    padding-right: 10px;
    padding-top: 20px !important;
     padding-bottom: 0;
}
.events-list .single-post h2 {
    padding-left: 10px;
}
.post-element span {
    display: inline !important;
}

.events-grid .single-post h2 {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 15px;
}
.post-in span {
  display: inline-block !important;
  margin-right: 10px;
}
.no-mb-xs{margin-bottom: 0px;}
.related-post .single-post img {
 border-bottom-left-radius: 5px !important;
border-bottom-right-radius: 5px !important;
border-top-right-radius: 5px !important;
width: 100%;
}

.related-post .single-post .post-details{
border: 0 solid;
padding-bottom: 0;
padding-left: 10px;	border: 0 solid;
padding-left: 10px;
}

.related-post .single-post .text {
  display: block;
}


}

 
 /*Smartphone*/ /*Smartphone*/ /*Smartphone*/ /*Smartphone*/
 /*Smartphone*/ /*Smartphone*/ /*Smartphone*/ /*Smartphone*/
 /*Smartphone*/ /*Smartphone*/ /*Smartphone*/ /*Smartphone*/
 /*Smartphone*/ /*Smartphone*/ /*Smartphone*/ /*Smartphone*/
 /*Smartphone*/ /*Smartphone*/ /*Smartphone*/ /*Smartphone*/


    
@media(max-width:480px) {
  
.events-list .post-date {
    position: static;
}  
	html {
		font-size: 65%;
	}
span.lesson-title {
  margin: 10px 0;
  width: 100%
}
.amount-per-month {
  width: 145%;
}
.amount-per-month::after {
	border-left: 98px solid transparent;
border-right: 98px solid transparent;
}
.events .single-post img {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  width: 100%;
}
.related-post .single-post img {
 border-bottom-left-radius: 5px !important;
border-bottom-right-radius: 5px !important;
border-top-right-radius: 5px !important;
width: 100%;
}
.related-post .single-post .text {
  display: none;
}
  section.course-event{
    padding-bottom: 0px;
  }
.course-list h3 {
    line-height: 28px;
}
.xs-device-2{width: 50% !important}

.slider-bottom-features .img-responsive {
  margin: 0 auto;
}
.slider-bottom-features h3,.slider-bottom-features p {
  text-align: center;
}
.homepage-slider p {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 30px;

}

header .container {
    padding: 0px;
}
.homepage-slider h3 {
    padding-bottom: 15px;
        margin-top: 0px;
}
.homepage-slider h1 {
 
    font-size: 30px;
}

.single-post img {
  border-radius:5px !important;
  width: 100%;
}
ul.course-category li{width: 100%}
.hv2 .signle-fid i {
  padding-top: 0;
  margin-bottom: 30px;
}

.hv2 .signle-fid i,.hv2 .signle-fid p,.hv2 .signle-fid h4{
  float: none;
  padding-top: 0;
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
}
.hv2 .single-professor .teacher {
  height: auto;
  width: 100%;
}
.hv2 .cnp img {
    width: 100%;
}

.latest-news h5 {
    margin-top: 20px;
}

.pagination > li > a, .pagination > li > span {

  margin-right: 7px;
  position: relative;
  width: auto;
  height: auto;
  padding: 5px 10px;
}
.pagination > li:first-child > a, .pagination > li:first-child > span{
  margin-right: 10px;
  padding-top: 4px;
}

.pagination > li:last-child > a, .pagination > li:last-child > span{
  margin-left: 10px;
  padding-top: 4px;
}
.latest-course .details {
  min-height: 215px;
}

.events-list .post-details{margin-top: -5px !important;}


}





@media(max-width:360px) {
.col-xs-6{width: 100%}
.hv2 .signle-fid {
  margin-bottom: 20px;
}

.amount-per-month::after {
  border-left: 208px solid transparent;
  border-right: 208px solid transparent;

}
.amount-per-month{width: 117%}
.xs-device-2{width: 100% !important}
.latest-course .details {
  min-height: auto;
}
.amount-per-month::after {
  border-left: 150px solid transparent !important;
  border-right: 150px solid transparent !important;
}
.awards-area li {
  width: 48% !important;
}
.amount-per-month::after {
  border-left: 128px solid transparent;
  border-right: 128px solid transparent;

}
.amount-per-month{width: 125%}


}


@media(max-width:320px) {
.amount-per-month::after {
  border-left: 130px solid transparent !important;
  border-right: 130px solid transparent !important;
}
.amount-per-month {
    width: 130%;
}
  }