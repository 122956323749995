.copystyle {
    margin-bottom: 44px;
    background-color: rgb(255 255 255 / 5%);
}

.requestcall .modal {
    background: rgba(255, 255, 255, 0.5);
    z-index: 9999999;
}

.requestcall .modal-dialog {
    top: 10%;
    width: 400px;
}

.requestcall .modal-content {
    border-radius: 0px;
    border: none;
    display: inline-block;
}

.requestcall button.close {
    opacity: inherit;
    background: #121212;
    text-shadow: inherit;
    color: #ffffff;
    font-size: 23px;
    padding: 5px 12px;
}

.ibform {
    background-color: #0d0d0d;
    padding: 25px;
    margin: 0;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    font-size: 20px;
    font-weight: normal;
    line-height: 27px;
    text-align: center;
}

.ibform span {
    color: #121212;
}

.inpuricon {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #e7e7e7;
    border: 1px solid #cdcdcd;
    line-height: 34px;
    text-align: center;
    position: absolute;
    margin: 13px 0 0 5px;
    z-index: 5;
}

.input-1 {
    background-color: #ffffff;
    border-radius: 4px;
    height: 44px;
    color: #8f8f8f;
    font-size: 15px;
    font-family: 'Arial', sans-serif;
    width: 100%;
    border: 0;
    padding: 0 0 0 50px;
    margin: 8px 0 0 0;
}

.ifbt {
    background-color: #121212;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    border: 0;
    border-radius: 4px;
    width: 100%;
    height: 44px;
    line-height: 44px;
    text-transform: uppercase;
    margin-top: 8px;
}

#sticky-footer {
    position: relative;
    height: 40px;
    z-index: 999;
}

.sticky-footer {
    background: #ffffff;
    position: fixed;
    height: 40px;
    bottom: 0;
    padding: 0px 0;
    width: 100%;
    box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.29);
}

.container {
    width: 88% !important;
}

.sticky-footer .or-txt,
.sticky-footer .foot-txt {
    font-size: 20px;
    color: #000000;
    font-weight: 800;
    font-family: 'Arial';
}

.sticky-footer .foot-txt a {
    text-decoration: none;
    color: #0d0d0d;
}

.support24 {
    background: #121212;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    color: #ffffff;
    display: inline-block;
    margin-right: 20px;
}

.support24 a {
    padding: 5px 20px;
    display: block;
    color: #ffffff;
    text-decoration: none;
}

.support24 i {
    font-size: 35px;
    vertical-align: middle;
    margin-right: 15px;
}

.call-back-bt {
    display: block;
}

.call-back-bt a {
    padding: 10px 15px;
    display: block;
    color: #ffffff;
    text-transform: uppercase;
    background: #121212;
    text-align: center;
    font-size: 15px;
    text-decoration: none;
}

@media(max-width:768px) {
    #sticky-footer {
        opacity: 0;
    }
}

.r-style {
    bottom: 1;
    position: absolute;
    width: 100%;
}

@media (max-width:768px) {
    .r-style {
        top: 1;
    }
}